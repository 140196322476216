import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const ApaItuBPR = () => (
  <div className="container overflow-hidden mx-auto py-6 mb-10 lg:mb-0 lg:py-8 relative">
    <StaticImage
      src="../images/bpr-vector.svg"
      objectFit="contain"
      className="absolute right-0"
      placeholder="none"
    />
    <h2 className="text-center text-lg py-8 mx-4 lg:py-2 lg:text-3xl font-medium md:text-xl">
      Apa itu BPR (Bank Perekonomian Rakyat)?
    </h2>
    <p className="text-center mx-8 text-sm lg:mx-auto lg:py-4 lg:w-10/12 text-gray-dark90">
      Bank Perekonomian Rakyat adalah bank yang melaksanakan kegiatan usaha secara konvensional
      atau berdasarkan prinsip syariah yang dalam kegiatannya tidak menyediakan jasa dalam lalu lintas giral secara langsung.
    </p>
  </div>
);

export default ApaItuBPR;
