import * as React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql, Link, Script } from 'gatsby';
import Slider from 'react-slick';
import Navbar from '../components/navbar';
import { Footer } from '../components/footer';
import '../styles/global.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SEO from '../SEO';
import { pageMeta, urlFor } from '../utils/common';
import Accordion from '../components/accordion/accordion';
import AccordionLottie from '../components/accordion/accordion-lottie';
import AccordionLottieMobile from '../components/accordion/accordion-lotie-mobile';
import formatNumber from '../utils/formatNumber';
import CardPengaduan from '../components/cardPengaduan';
import BenefitDepositoBPR from '../components/benefitDepositoBPR';
import CardDepositoBPR from '../components/cardDepositoBPR';
import ApaItuBPR from '../components/apaItuBPR';
import useSmartScript from '../hooks/useSmartScript';
import { ONELINK_SCRIPT_URL } from '../configs/appsflyer';
import Wrapper from '../components/Wrapper';
import { CUSTOM_EVENT, pushEvent } from '../configs/clevertap';
import Button from '../components/button/Button';
import GTM_TRACKERS from '../../constants/gtm-trackers';

export const query = graphql`
  query sanityPromoList {
    allSanityPromo (filter: { subCategories: { elemMatch: { title: { nin: ["Affiliate", "Privileges"] } } }, isActive: { eq: true } }) {
      nodes {
        id
        lang
        category
        slug {
          current
        }
        title
        mainImageURL
        mainImage {
          asset {
            _id
          }
        }
        mainImageMobile {
          asset {
            _id
          }
        }
        publishedAt(locale: "id", formatString: "DD MMMM YYYY")
        _rawBody
        author {
          id
          name
        }
        buttonCtas {
          url
          text
          scheme
          _type
          _rawCtaImage
          _key
          ctaImage {
            _key
            _type
            asset {
              _id
              id
            }
          }
        }
        list {
          _id
          _type
          label
          list {
            ... on SanityPromo {
              _id
              title
              category
              subCategories {
                _id
                title
              }
              author {
                name
              }
              lang
              mainImage {
                asset {
                  _id
                }
              }
              slug {
                current
              }
              publishedAt(locale: "id", formatString: "DD MMMM YYYY")
            }
            ... on SanityBlog {
              _id
              title
              category
              subCategories {
                _id
                title
              }
              author {
                name
              }
              lang
              mainImage {
                asset {
                  _id
                }
              }
              slug {
                current
              }
              publishedAt(locale: "id", formatString: "DD MMMM YYYY")
            }
          }
        }
      }
    }
  }`;

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  responsive: [
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: false,
      },
    },
  ],
};

const settingsMedia = {
  infinite: true,
  speed: 500,
  slidesToShow: 6,
  slidesToScroll: 2,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  responsive: [
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false,
        autoplay: false,
      },
    },
  ],
};
const Index = ({ data }) => {
  const [dataTotal, setDataTotal] = React.useState([]);
  const [dataByYear, setDataByYear] = React.useState([]);
  const [yearNow, setYearNow] = React.useState([]);
  const [thisMonth, setThisMonth] = React.useState([]);

  React.useEffect(() => {
    const reqDataTotal = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch('/api-v2/deposito/statistic-deposito', reqDataTotal)
      .then((response) => response.json())
      .then((dataResponse) => {
        const { total } = dataResponse.data.totalAll;
        setDataTotal(total);
      })
      .catch((err) => console.error(err));
  }, [setDataTotal]);

  React.useEffect(() => {
    const reqDataByYear = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch('/api-v2/deposito/statistic-deposito', reqDataByYear)
      .then((response) => response.json())
      .then((dataResponse) => {
        const { total } = dataResponse.data.totalByYear;

        const resYear = dataResponse.data.totalByYear.year;
        const options = { month: 'long', year: 'numeric' };
        const month = new Date().toLocaleString('id-ID', options);

        setThisMonth(month);
        setDataByYear(total);
        setYearNow(resYear);
      })
      .catch((err) => console.error(err));
  }, [setDataByYear]);

  const { smartScriptUrl, setIsSmartScriptLoaded } = useSmartScript();

  const total = formatNumber(dataTotal);
  const totalByYear = formatNumber(dataByYear);

  return (
    <Wrapper>
      <SEO
        title={pageMeta.mainTitle}
        metaImage={urlFor(data.allSanityPromo.nodes[0].mainImageMobile).url()}
        metaDescription={pageMeta.mainDesc}
        author={pageMeta.author}
      />
      <Script src={ONELINK_SCRIPT_URL} onLoad={() => { setIsSmartScriptLoaded(true); }} />
      <Navbar />
      <div
        className={`w-full bg-gradient-to-r from-blue-light40 to-green-light40 p-3 ${GTM_TRACKERS.HOME.AJAK_TEMAN}`}
      >
        <Link
          onClick={() => pushEvent(CUSTOM_EVENT.DIRECT_REFERRAL)}
          to="/referral"
          className="flex items-center justify-center gap-x-4"
        >
          <div className="hidden lg:block">
            <StaticImage
              src="../images/referral/cta-home/polkadot-left.webp"
              alt="polkadot-left"
              objectFit="contain"
              className="w-14"
            />
          </div>
          <div className="flex gap-x-2">
            <StaticImage
              src="../images/referral/cta-home/rocket.webp"
              alt="rocket"
              objectFit="contain"
              width={32}
              height={32}
            />
            <p className="text-xs font-medium text-white self-center">
              Program Referral Ajak Teman Dapat Bonus
            </p>
            <StaticImage
              src="../images/referral/cta-home/arrow-right.webp"
              alt="arrow-right"
              objectFit="contain"
              width={24}
              height={24}
            />
          </div>
          <div className="hidden lg:block">
            <StaticImage
              src="../images/referral/cta-home/polkadot-right.webp"
              alt="polkadot-right"
              objectFit="contain"
              className="w-14"
            />
          </div>
        </Link>
      </div>
      <div className="w-full bg-blue-light40 relative">
        <div className="absolute -top-10 right-0 hidden lg:block">
          <StaticImage
            src="../images/home/ellipse.webp"
            alt="ellipse"
            objectFit="contain"
            placeholder="none"
          />
        </div>
        <div className="hidden lg:block absolute bottom-0 right-8">
          <StaticImage
            src="https://cdn.sanity.io/images/iaemwymj/preview/32e93bc3fbe577a9067704071956f134658d838a-1670x1114.webp"
            alt="Hero Image"
            objectFit="contain"
            width={720}
          />
        </div>
        <div className="lg:grid lg:grid-cols-2 py-12 lg:px-40 lg:py-20">
          <div className="flex flex-col gap-y-8">
            <p className="text-center px-4 lg:px-0 lg:text-left text-xl lg:text-3xl text-white font-semibold lg:w-11/12 md:w-5/6 md:mx-auto lg:mx-0">
              Aplikasi Marketplace Pertama Khusus Produk Deposito BPR di Indonesia
            </p>
            <div className="flex gap-x-5">
              <div className={GTM_TRACKERS.HOME.UNDUH_APLIKASI}>
                <Button
                  text="UNDUH APLIKASI"
                  href={smartScriptUrl}
                  className="px-4 py-2 hidden lg:block"
                />
              </div>
              <div className={GTM_TRACKERS.HOME.GOOGLE_PLAY}>
                <a
                  href={smartScriptUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="hidden lg:block"
                >
                  <StaticImage
                    src="../images/Google.png"
                    alt="Google-logo"
                    objectFit="contain"
                    className="w-32 mx-2 lg:w-32 md:w-16"
                    placeholder="none"
                  />
                </a>
              </div>
              <div className={GTM_TRACKERS.HOME.APP_STORE}>
                <a
                  href={smartScriptUrl}
                  target="_blank"
                  rel="noreferrer"
                  className="hidden lg:block"
                >
                  <StaticImage
                    src="../images/Apple.png"
                    alt="apple-logo"
                    objectFit="contain"
                    className="w-32"
                    placeholder="none"
                  />
                </a>
              </div>
            </div>
            <div className="box-register mt-14 hidden lg:flex relative">
              <div>
                <p className="text-sm text-white md-text-base">Terdaftar di</p>
                <div className="flex justify-between py-4 lg:py-0">
                  <StaticImage
                    src="../images/kominfo-logo-new.png"
                    alt="kominfo-logo"
                    objectFit="contain"
                    className="w-16 lg:w-28 md-svg-img"
                    placeholder="none"
                  />
                  <StaticImage
                    src="../images/fintech-logo-new.png"
                    alt="kominfo-logo"
                    objectFit="contain"
                    className="w-16 lg:w-24 md-svg-img"
                    placeholder="none"
                  />
                </div>
              </div>
              <div>
                <p className="text-sm text-white md-text-base font-normal">Berstatus direkomendasikan OJK</p>
                <p className="font-semibold text-xs text-white lg:text-sm py-4 md-text-xs">
                  No. S-272/IK.01/2024
                </p>
              </div>
              <StaticImage
                src="../images/shield.png"
                objectFit="contain"
                alt="shield"
                className="absolute w-6 right-0 top-0"
                placeholder="none"
              />
            </div>
            <div className="absolute w-96 left-0 bottom-4 -mt-16 -ml-64 hidden lg:block">
              <StaticImage
                src="../images/hero.svg"
                alt="eclipse-gray"
                objectFit="contain"
                placeholder="none"
              />
            </div>
          </div>
          <div className="px-2 md:w-5/6 md:flex md:mx-auto lg:hidden">
            <StaticImage
              src="https://cdn.sanity.io/images/iaemwymj/preview/32e93bc3fbe577a9067704071956f134658d838a-1670x1114.webp"
              alt="Hero Image"
              objectFit="contain"
              placeholder="none"
            />
          </div>
          <div className="flex flex-col items-center gap-y-5 lg:hidden">
            <div className="box-register lg:justify-between flex md:justify-around lg:hidden">
              <div>
                <p className="text-xs text-white md:text-base">Terdaftar di</p>
                <div className="flex justify-between pt-2 lg:pt-0">
                  <StaticImage
                    src="../images/kominfo-logo-new.png"
                    alt="kominfo-logo"
                    objectFit="contain"
                    className="w-16 lg:w-28 md:w-24"
                    placeholder="none"
                  />
                  <StaticImage
                    src="../images/fintech-logo-new.png"
                    alt="kominfo-logo"
                    objectFit="contain"
                    className="w-16 lg:w-24 md:w-20"
                    placeholder="none"
                  />
                </div>
              </div>
              <div>
                <p className="text-xs text-white md:text-base font-normal">Berstatus direkomendasikan OJK</p>
                <p className="font-medium text-2xs pt-5 lg:pt-0 text-white lg:text-base md:text-base">
                  No. S-272/IK.01/2024
                </p>
              </div>
              <StaticImage
                src="../images/shield.png"
                objectFit="contain"
                alt="shield"
                className="absolute shield w-7 md:right-32"
                placeholder="none"
              />
            </div>
            <div className="flex lg:hidden justify-between md:justify-center w-11/12">
              <a
                href={smartScriptUrl}
                target="_blank"
                rel="noreferrer"
                className="lg:hidden"
              >
                <StaticImage
                  src="../images/Google.png"
                  alt="Google-logo"
                  objectFit="contain"
                  className="w-40 mx-2"
                  placeholder="none"
                />
              </a>
              <a
                href={smartScriptUrl}
                target="_blank"
                rel="noreferrer"
                className="lg:hidden"
              >
                <StaticImage
                  src="../images/Apple.png"
                  alt="apple-logo"
                  objectFit="contain"
                  className="w-40"
                  placeholder="none"
                />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-green-light20">
        <div className="container mx-auto lg:w-full py-8">
          <div className="lg:flex justify-between mx-auto lg:w-2/3">
            <div className="flex justify-center lg:justify-start lg:text-left">
              <StaticImage
                src="../images/total-deposito.png"
                alt="total-deposito"
                objectFit="contain"
                className="w-14 hidden-img lg:block"
              />
              <div className="self-center lg:ml-4">
                <p className="text-gray-dark text-sm lg:text-base">Total Deposito Tersalurkan (Sejak Berdiri)</p>
                <p className="text-blue-light40 text-xl lg:text-2xl font-medium text-center lg:text-left">Rp {total}</p>
              </div>
            </div>
            <div className="border-vertical hidden lg:block" />
            <div className="flex justify-center lg:justify-start mt-4 lg:mt-0 lg:text-left">
              <StaticImage
                src="../images/deposito-tersalurkan.png"
                alt="deposito-tersalurkan"
                objectFit="contain"
                className="w-14 hidden-img lg:block"
              />
              <div className="self-center lg:ml-4">
                <p className="text-gray-dark text-sm lg:text-base">Deposito Tersalurkan (Tahun {yearNow})</p>
                <p className="text-blue-light40 text-xl lg:text-2xl font-medium text-center lg:text-left">Rp {totalByYear}</p>
              </div>
            </div>
          </div>
          <div className="text-center pt-10 text-gray-dark text-xs lg:text-base italic">Diambil per {thisMonth}</div>
        </div>
      </div>

      <BenefitDepositoBPR />

      <div className="mx-auto horizontal-green-border mt-16 lg:mt-0" />

      <ApaItuBPR />

      <div className="mx-auto horizontal-green-border" />

      <div className="mt-10 lg:mt-0 lg:py-10 relative">
        <p className="text-center py-5 text-lg mx-8 lg:mx-0 lg:text-3xl font-medium md:text-xl">
          3 Langkah mudah memulai Deposito
        </p>
        <StaticImage
          src="../images/3-step.svg"
          objectFit="contain"
          className="absolute left-0 hidden-img"
          placeholder="none"
        />
        <div className="justify-items-center mx-4 hidden lg:block lg:mx-0">
          <div className="self-center">
            <AccordionLottie />
          </div>
        </div>
        <div className="justify-items-center mx-4 relative lg:hidden lg:mx-0">
          <div className="self-center">
            <AccordionLottieMobile />
          </div>
        </div>
      </div>

      <div className="bg-blue-light40 pt-12 pb-[322px] md:pb-12 lg:py-20 relative overflow-hidden">
        <div className="hidden lg:block absolute -top-10 -left-10">
          <StaticImage
            src="../images/save.svg"
            objectFit="contain"
            placeholder="none"
          />
        </div>
        <div className="flex flex-col md:flex-row">
          <div className="flex flex-col items-center md:items-start gap-y-4 text-white px-4 lg:px-40 z-20">
            <div className="w-[157px] lg:w-[249px]">
              <StaticImage
                src="../images/home/campaign.webp"
                alt="#PilihYangTepat"
                objectFit="contain"
              />
            </div>
            <h3 className="w-96 text-white text-center text-3xl md:text-left font-semibold">
              Promo & Cashback untuk Ekstra Cuan!
            </h3>
            <div className={`flex ${GTM_TRACKERS.PROMO.KLIK_DISINI}`}>
              <Button
                text="KLIK DISINI"
                isInternal
                href="/promo"
                className="px-16 py-2"
              />
            </div>
          </div>
          <div className="md:hidden z-10 absolute bottom-0">
            <StaticImage
              src="https://cdn.sanity.io/images/iaemwymj/preview/ad0bbb45d7de2ca50010797d31b61cfd241e1b8b-1293x987.webp"
              alt="Banner"
              objectFit="contain"
            />
          </div>
          <div className="hidden md:block md:w-[560px] lg:w-[840px] z-10 absolute bottom-0 right-0">
            <StaticImage
              src="https://cdn.sanity.io/images/iaemwymj/preview/0d7687003c990394ca09c4841a1dd7c8e95d9056-2573x1294.webp"
              alt="Banner"
              objectFit="contain"
            />
          </div>
        </div>
        <div
          className="hidden lg:block absolute top-[80px] left-[840px] z-0"
        >
          <StaticImage
            src="../images/home/ellipse-faded.webp"
            objectFit="contain"
            placeholder="none"
          />
        </div>
      </div>

      <div className="container mx-auto relative">
        <p className="text-center text-lg w-11/12 mx-auto lg:text-3xl mt-14 lg:mb-10 font-medium md:text-xl">
          Aplikasi terpercaya khusus produk Deposito BPR
        </p>
        <StaticImage
          src="../images/app-trust.svg"
          objectFit="contain"
          className="absolute left-0 -ml-44 -z-20"
          placeholder="none"
        />
        <div className="max-w-full text-center mx-8 my-10 lg:mx-auto lg:w-5/6 lg:my-10">
          <Slider {...settings}>
            <div className="box-number text-center mx-auto my-16 lg:my-8 lg:mx-8">
              <StaticImage
                src="../images/number-img-1.png"
                alt="number-img"
                objectFit="contain"
                className="w-20 lg:w-24 flex mx-auto -mt-12"
                placeholder="none"
              />
              <div>
                <p className="py-2 font-bold text-2xl lg:text-3xl">376+</p>
                <p className="text-sm py-1 lg:py-0 text-gray-dark">BPR Terdaftar</p>
              </div>
            </div>
            <div className="box-number text-center mx-auto my-16 lg:my-8 lg:mx-8">
              <StaticImage
                src="../images/number-img-2.png"
                alt="number-img"
                objectFit="contain"
                className="w-20 lg:w-24 flex mx-auto -mt-12"
                placeholder="none"
              />
              <div className="self-auto">
                <p className="py-2 font-bold text-2xl lg:text-3xl">100K+</p>
                <p className="text-sm py-1 lg:py-0 text-gray-dark">Download</p>
              </div>
            </div>
            <div className="box-number text-center mx-auto my-16 lg:my-8 lg:mx-8">
              <StaticImage
                src="../images/number-img-3.png"
                alt="number-img"
                objectFit="contain"
                className="w-20 lg:w-24 flex mx-auto -mt-12"
                placeholder="none"
              />
              <div>
                <p className="py-2 font-bold text-2xl lg:text-3xl">10K+</p>
                <p className="text-sm py-1 lg:py-0 text-gray-dark">Review app*</p>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      <div className="mx-auto horizontal-green-border mt-20 lg:mt-0" />

      <div className="container mx-auto my-14 lg:my-20">
        <p className="text-center text-lg lg:text-3xl lg:mb-10 font-medium md:text-xl">Testimoni Pengguna</p>
        <div className="max-w-full text-center my-3">
          <Slider {...settings}>
            <div className="box-slider text-left py-6 px-9">
              <div className="flex">
                <StaticImage
                  src="../images/review-1.png"
                  alt="review-img"
                  objectFit="contain"
                  className="w-20 rounded-full"
                  placeholder="none"
                />
                <p className="text-lg font-semibold self-center ml-3 lg:ml-0 lg:px-3">Abeltine Yustriani</p>
              </div>
              <StaticImage
                src="../images/star-review.svg"
                alt="review-img"
                objectFit="contain"
                className="py-2 mt-2"
                placeholder="none"
              />
              <p className="text-sm font-normal py-2 text-left text-gray-dark90 leading-6">
                Aplikasi keren di bidang keuangan. Buka deposito ga perlu ke bank. Praktis, aman, dan nyaman.
                Bunga tinggi dan banyak promo tiap bulan. Jadi double cuan deh!
              </p>
            </div>
            <div className="box-slider text-left py-6 px-9">
              <div className="flex">
                <StaticImage
                  src="../images/review-img.png"
                  alt="review-img"
                  objectFit="contain"
                  className="w-20 rounded-full"
                  placeholder="none"
                />
                <p className="text-lg font-semibold self-center text-left ml-3 lg:ml-0 lg:px-3">Chorina Sweet Friendtian</p>
              </div>
              <StaticImage
                src="../images/star-review.svg"
                alt="review-img"
                objectFit="contain"
                className="py-2 mt-2"
                placeholder="none"
              />
              <p className="text-sm font-normal py-2 text-left text-gray-dark90 leading-6">
                Deposito paling mudah dan paling aman ya di sini 😅 awalnya takut duit hilang.. setelah
                saya jalani deposito BPR saya senang sekali.. karna banyak promo menarik , aman, suku
                bunga tinggi, dan kita bebas memilih jangka waktu yang kita mau.. thx DepositoBPR hidupku jadi bermakna 🙏👍
              </p>
            </div>
            <div className="box-slider text-left py-6 px-9">
              <div className="flex">
                <StaticImage
                  src="../images/review-2.png"
                  alt="review-img"
                  objectFit="contain"
                  className="w-20 rounded-full"
                  placeholder="none"
                />
                <p className="text-lg font-semibold self-center ml-3 lg:ml-0 lg:px-3">hery eka pelita listijorini</p>
              </div>
              <StaticImage
                src="../images/star-review.svg"
                alt="review-img"
                objectFit="contain"
                className="py-2 mt-2"
                placeholder="none"
              />
              <p className="text-sm font-normal py-2 text-left text-gray-dark90 leading-6">
                Aplikasi marketplace deposito di BPR yang lengkap Ada banyak pilihan bunga,
                tenor dan minimal pendanaan yang bisa dipilih sesuai kebutuhan Banyak promo cashback
                juga yang bikin lebih untung
              </p>
            </div>
          </Slider>
        </div>
      </div>

      <div className="container mx-auto mt-20 mb-40">
        <p className="text-center text-lg lg:text-3xl lg:mb-10 font-medium md:text-xl">Telah diliput oleh</p>
        <div className="">

          <div className="lg:hidden">
            <Slider {...settingsMedia}>
              <div className="flex flex-col p-10">
                <div className="flex flex-row gap-10">
                  <StaticImage
                    src="../images/mediaPartner/Logo_Tech_in_Asia.png"
                    alt="Tech in Asia"
                    objectFit="contain"
                    className="m-auto w-1/3"
                    placeholder="none"
                  />
                  <StaticImage
                    src="../images/mediaPartner/Logo_Kompas.png"
                    alt="Kompas"
                    objectFit="contain"
                    className="m-auto w-1/3"
                    placeholder="none"
                  />
                  <StaticImage
                    src="../images/mediaPartner/Logo_Tempo_1.png"
                    alt="Tempo 1"
                    objectFit="contain"
                    className="m-auto w-1/3"
                    placeholder="none"
                  />
                </div>
                <div className="flex flex-row gap-10">
                  <StaticImage
                    src="../images/mediaPartner/Logo_CNBC.png"
                    alt="CNBC"
                    objectFit="contain"
                    className="m-auto w-1/3"
                    placeholder="none"
                  />
                  <StaticImage
                    src="../images/mediaPartner/Logo_Jawa_Pos.png"
                    alt="Jawa Pos"
                    objectFit="contain"
                    className="m-auto w-1/3"
                    placeholder="none"
                  />
                  <StaticImage
                    src="../images/mediaPartner/Logo_Tribun_News.png"
                    alt="Tribun News"
                    objectFit="contain"
                    className="m-auto w-1/3"
                    placeholder="none"
                  />
                </div>
              </div>

              <div className="flex flex-col p-10">
                <div className="flex flex-row gap-10">
                  <StaticImage
                    src="../images/mediaPartner/Logo_Suara.png"
                    alt="Suara"
                    objectFit="contain"
                    className="py-2 mt-2 w-1/2"
                    placeholder="none"
                  />
                  <StaticImage
                    src="../images/mediaPartner/Logo_Merdeka.png"
                    alt="Merdeka"
                    objectFit="contain"
                    className="py-2 mt-2 w-1/2"
                    placeholder="none"
                  />
                  <StaticImage
                    src="../images/mediaPartner/Logo_Liputan_7.png"
                    alt="Liputan 7"
                    objectFit="contain"
                    className="py-2 mt-2 w-1/2"
                    placeholder="none"
                  />
                </div>
                <div className="flex flex-row gap-10">
                  <StaticImage
                    src="../images/mediaPartner/Logo_SindoNews.png"
                    alt="SindoNews"
                    objectFit="contain"
                    className="py-2 mt-2 w-1/2"
                    placeholder="none"
                  />
                  <StaticImage
                    src="../images/mediaPartner/Logo_ANTARANews.png"
                    alt="ANTARA News"
                    objectFit="contain"
                    className="py-2 mt-2 w-1/2"
                    placeholder="none"
                  />
                  <StaticImage
                    src="../images/mediaPartner/Logo_INews.png"
                    alt="INews"
                    objectFit="contain"
                    className="py-2 mt-2 w-1/2"
                    placeholder="no1ne"
                  />
                </div>
              </div>
            </Slider>
          </div>

          <div className="lg:flex hidden flex-wrap justify-center">
            <div className="flex justify-center items-center w-1/6">
              <StaticImage
                src="../images/mediaPartner/Logo_Tech_in_Asia.png"
                alt="Tech in Asia"
                objectFit="contain"
                className="py-2 mt-2 w-1/2"
                placeholder="none"
              />
            </div>
            <div className="flex justify-center items-center w-1/6">
              <StaticImage
                src="../images/mediaPartner/Logo_Kompas.png"
                alt="Kompas"
                objectFit="contain"
                className="py-2 mt-2 w-1/2"
                placeholder="none"
              />
            </div>
            <div className="flex justify-center items-center w-1/6">
              <StaticImage
                src="../images/mediaPartner/Logo_Tempo_1.png"
                alt="Tempo 1"
                objectFit="contain"
                className="py-2 mt-2 w-1/2"
                placeholder="none"
              />
            </div>
            <div className="flex justify-center items-center w-1/6">
              <StaticImage
                src="../images/mediaPartner/Logo_CNBC.png"
                alt="CNBC"
                objectFit="contain"
                className="py-2 mt-2 w-1/2"
                placeholder="none"
              />
            </div>
            <div className="flex justify-center items-center w-1/6">
              <StaticImage
                src="../images/mediaPartner/Logo_Jawa_Pos.png"
                alt="Jawa Pos"
                objectFit="contain"
                className="py-2 mt-2 w-1/2"
                placeholder="none"
              />
            </div>
            <div className="flex justify-center items-center w-1/6">
              <StaticImage
                src="../images/mediaPartner/Logo_Tribun_News.png"
                alt="Tribun News"
                objectFit="contain"
                className="py-2 mt-2 w-1/2"
                placeholder="none"
              />
            </div>
            <div className="flex justify-center items-center w-1/6">
              <StaticImage
                src="../images/mediaPartner/Logo_Suara.png"
                alt="Suara"
                objectFit="contain"
                className="py-2 mt-2 w-1/2"
                placeholder="none"
              />
            </div>
            <div className="flex justify-center items-center w-1/6">
              <StaticImage
                src="../images/mediaPartner/Logo_Merdeka.png"
                alt="Merdeka"
                objectFit="contain"
                className="py-2 mt-2 w-1/2"
                placeholder="none"
              />
            </div>
            <div className="flex justify-center items-center w-1/6">
              <StaticImage
                src="../images/mediaPartner/Logo_Liputan_7.png"
                alt="Liputan 7"
                objectFit="contain"
                className="py-2 mt-2 w-1/2"
                placeholder="none"
              />
            </div>
            <div className="flex justify-center items-center w-1/6">
              <StaticImage
                src="../images/mediaPartner/Logo_SindoNews.png"
                alt="SindoNews"
                objectFit="contain"
                className="py-2 mt-2 w-1/2"
                placeholder="none"
              />
            </div>
            <div className="flex justify-center items-center w-1/6">
              <StaticImage
                src="../images/mediaPartner/Logo_ANTARANews.png"
                alt="ANTARA News"
                objectFit="contain"
                className="py-2 mt-2 w-1/2"
                placeholder="none"
              />
            </div>
            <div className="flex justify-center items-center w-1/6">
              <StaticImage
                src="../images/mediaPartner/Logo_INews.png"
                alt="INews"
                objectFit="contain"
                className="py-2 mt-2 w-1/2"
                placeholder="none"
              />
            </div>
          </div>
        </div>
      </div>

      <CardDepositoBPR smartScriptUrl={smartScriptUrl} />

      <div className="container mx-auto my-10 lg:my-14">
        <p className="text-center text-lg lg:text-3xl lg:mb-5 font-medium">FAQ</p>
        <Accordion />
        <a href="/faq" className="mx-auto see-faq mb-10 rounded-green">
          Lihat Semua FAQ
        </a>
      </div>

      <CardPengaduan />
      <Footer />
    </Wrapper>
  );
};

Index.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Index;
