import * as React from 'react';
import Loadable from 'react-loadable';
import { formatDate } from '../utils/datetime';

export const CUSTOM_EVENT = {
  DAFTAR_SEKARANG: 'LpDaftarReferral',
  DAFTAR_SEKARANG_DETAIL: 'LpDaftarSekarang',
  GABUNG_REFERRAL: 'LpGabungReferral',
  PELAJARI_LEBIH_LANJUT: 'LpPelajariLebihLanjut',
  GABUNG_KOMUNITAS_REFERRAL: 'LpGabungKomunitasReferral',
  DOWNLOAD_PANDUAN_PROGRAM: 'LpDownloadPanduanProgram',
  DIRECT_REFERRAL: 'Direct_LpReferral',
};

export const CUSTOM_EVENT_PYT = {
  MILLENIAL_PROFESSIONAL: {
    LOAD: 'Lp_PYT1_MillenialProfessional',
    DOWNLOAD_1: 'Lp_PYT1_download1',
    DOWNLOAD_2: 'Lp_PYT1_download2',
    WHATSAPP: 'Lp_PYT1_WA',
    TELEGRAM: 'Lp_PYT1_Telegram',
  },
  YOUNG_ENTREPRENEUR: {
    LOAD: 'Lp_PYT2_YoungEntrepreneur',
    DOWNLOAD_1: 'Lp_PYT2_download1',
    DOWNLOAD_2: 'Lp_PYT2_download2',
    WHATSAPP: 'Lp_PYT2_WA',
    TELEGRAM: 'Lp_PYT2_Telegram',
  },
  ACTIVE_WOMAN: {
    LOAD: 'Lp_PYT3_ActiveWoman',
    DOWNLOAD_1: 'Lp_PYT3_download1',
    DOWNLOAD_2: 'Lp_PYT3_download2',
    WHATSAPP: 'Lp_PYT3_WA',
    TELEGRAM: 'Lp_PYT3_Telegram',
  },
};

export const LoadAbleClevertap = Loadable({
  loader: () => new Promise((resolve, reject) => {
    try {
      const clevertap = require('clevertap-web-sdk');
      resolve(clevertap);
    } catch (error) {
      reject(error);
    }
  }),
  loading: () => <div>Loading...</div>,
});

export async function pushEvent(event, data = undefined) {
  const clevertap = await LoadAbleClevertap.preload();

  const payload = {
    Date: formatDate(),
  };

  if (data && data.email) payload.Email = data.email;
  if (data && data.name) payload.Name = data.name;

  clevertap.event.push(event, payload);
}
