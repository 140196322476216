import React, { useState } from 'react';
import Lottie from 'lottie-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import step1 from '../../images/step-1.json';
import step2 from '../../images/step-2.json';
import step3 from '../../images/step-3.json';
import './style.css';

const dataCollection = [
  {
    question: 'Unduh dan Daftar',
    /* eslint-disable-next-line max-len */
    answer: 'Unduh aplikasi di Google Playstore / App Store dan lanjutkan dengan Registrasi data diri secara lengkap dan benar.',
  },
  {
    question: 'Verifikasi dan aktivasi akun',
    /* eslint-disable-next-line max-len */
    answer: 'Lanjut ke Tahap Verifikasi Email, kemudian Klik Hubungi CH untuk aktivasi akun, dan terakhir tandatangan Akun Privy. Akun DepositoBPR by Komunal telah aktif dan siap untuk melakukan pendanaan deposito pertama kamu.',
  },
  {
    question: 'Pilih deposito yang diminati',
    /* eslint-disable-next-line max-len */
    answer: 'Klik Menu PELUANG, kemudian bebas pilih deposito yang kamu inginkan berdasarkan bunga tertinggi / minimum penempatan deposito / minimum tenor / lokasi domisili.',
  },
];

export default function AccordionLottie() {
  const [accordion, setActiveAccordion] = useState(0);

  function toggleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  }

  return (
    <>
      <div className="container mx-auto mb-10">
        <div className="lg:flex justify-center">
          <div className="flex justify-center">
            {
              // eslint-disable-next-line no-nested-ternary
              accordion === 0 ? <Lottie className="w-1/2" animationData={step1} />
                // eslint-disable-next-line no-nested-ternary
                : accordion === 1 ? <Lottie className="w-1/2" animationData={step2} />
                  : accordion === 2 ? <Lottie className="w-1/2" animationData={step3} />
                    : <Lottie className="w-1/2" animationData={step1} />
            }
          </div>
          <div className="accordion-lottie self-center">
            {dataCollection.map((item, index) => (
              <div
                key={item.question}
                onClick={() => toggleAccordion(index)}
                onKeyDown={() => toggleAccordion(index)}
                role="button"
                tabIndex="0"
                className={`${accordion === index ? 'active-lotie' : ''}`}
              >
                <div className="flex justify-between">
                  <div className="accordion-faq-heading">
                    <div className="bg-green-light40 text-blue-light40 w-16 text-3xl text-center py-2 font-bold rounded-full mr-2">
                      {index === 0 ? 1 : index + 1}
                    </div>
                    <h3 className={`${accordion === index ? 'active active-lotie' : ''} font-medium text-lg lg:ml-2`}>
                      {item.question}
                    </h3>
                  </div>
                  <div className="my-3">
                    {accordion === index ? (
                      <>
                        <span className="verticle active">
                          <FontAwesomeIcon icon={faAngleDown} />
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="verticle">
                          <FontAwesomeIcon icon={faAngleRight} />
                        </span>
                      </>
                    )}
                  </div>
                </div>
                <div>
                  <p className={`${accordion === index ? 'active' : 'inactive'} pt-5 text-gray-dark`}>
                    {item.answer}
                  </p>
                </div>
              </div>
            ))}
            {/* <button className="bg-blue-light40 text-white py-3 px-6 rounded-lg mx-4 lg:mx-0" type="button">
              Lihat Selengkapnya
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
}
