import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Slider from 'react-slick';

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  responsive: [
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: true,
        autoplay: false,
      },
    },
  ],
};

const BenefitDepositoBPR = () => {
  const [rateLps, setRateLps] = useState('');
  const fetchRateLpsData = () => {
    const reqRateLps = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    fetch('/api-v2-customer/settings', reqRateLps)
      .then((response) => response.json())
      .then((json) => {
        setRateLps(json.data.find((data) => data.parameter === 'lps_rate').value);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchRateLpsData();
  }, []);

  return (
    <div className="container mx-auto my-6 lg:my-10">
      <p className="text-center mx-auto w-5/6 py-7 text-lg lg:py-8 lg:text-3xl font-medium md:text-xl">
        Kelebihan Menyimpan di DepositoBPR by Komunal
      </p>
      <div className="text-center max-w-full py-8 mx-8 lg:mx-0 lg:py-0">
        <Slider {...settings}>
          <div>
            <StaticImage
              src="../images/more-1.png"
              alt="more-1"
              objectFit="contain"
              className="w-28"
              placeholder="none"
            />
            <p className="py-4 text-xl font-medium">Bunga hingga {rateLps}% p.a</p>
            <p className="text-blue-light40 font-bold">#LebihUntung</p>
            <p className="mx-auto w-72 text-gray-dark90 text-desc-slide">
              Sesuai dengan aturan LPS dan lebih tinggi dari bunga deposito biasa
            </p>
          </div>
          <div className="lg:my-0">
            <StaticImage
              src="../images/more-2.png"
              alt="more-2"
              objectFit="contain"
              className="w-28"
              placeholder="none"
            />
            <p className="py-4 text-xl font-medium">Dijamin LPS hingga Rp 2 Milyar</p>
            <p className="text-blue-light40 font-bold">#LebihAman</p>
            <p className="mx-auto w-80 text-gray-dark90 text-desc-slide">
              Seluruh penempatan deposito telah dijamin oleh LPS (Lembaga Penjamin Simpanan) hingga Rp 2 Miliar per nasabah per bank
            </p>
          </div>
          <div>
            <StaticImage
              src="../images/more-3.png"
              alt="more-3"
              objectFit="contain"
              className="w-28"
              placeholder="none"
            />
            <p className="py-4 text-xl font-medium">Nikmati akses ratusan BPR</p>
            <p className="text-blue-light40 font-bold">#LebihPraktis</p>
            <p className="mx-auto w-80 text-gray-dark90 text-desc-slide">
              Cukup 1x daftar dan nikmati kemudahan buka deposito di ratusan BPR terseleksi di Indonesia
            </p>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default BenefitDepositoBPR;
