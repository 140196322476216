import * as React from 'react';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
import { DEFAULT_SMART_SCRIPT_URL } from '../configs/appsflyer';
import Button from './button/Button';
import GTM_TRACKERS from '../../constants/gtm-trackers';

const CardDepositoBPR = ({ smartScriptUrl }) => (
  <div className="my-16 lg:mx-28 bg-blue-light40 rounded-none lg:rounded-3xl flex flex-col lg:flex-row lg:relative">
    <div className="flex flex-col gap-y-14 px-4 py-14 items-center lg:items-start lg:w-4/6 lg:px-20 lg:py-20 z-20">
      <div className="flex flex-col gap-y-6 items-center lg:items-start">
        <StaticImage
          src="../images/home/depositobpr-logo.webp"
          alt="DepositoBPR"
          objectFit="contain"
          className="w-[150px]"
        />
        <h3 className="lg:w-[480px] text-2lg lg:text-2xl text-white font-medium text-center lg:text-left">
          Coba Sekarang Juga Produk Simpanan Rasa Investasi!
        </h3>
      </div>
      <div className="lg:hidden w-[326px]">
        <StaticImage
          src="https://cdn.sanity.io/images/iaemwymj/preview/d8340b8567513a8e06fea1d7f4d098b301671fae-1304x1344.webp"
          alt="Persuasion"
          objectFit="contain"
        />
      </div>
      <div className="flex justify-between gap-x-4 lg:gap-x-5 lg:justify-start">
        <div className={GTM_TRACKERS.HOME.UNDUH_APLIKASI}>
          <Button
            text="UNDUH APLIKASI"
            href={smartScriptUrl}
            className="px-5 py-2 hidden lg:block"
          />
        </div>
        <div className={GTM_TRACKERS.HOME.GOOGLE_PLAY}>
          <a
            href={smartScriptUrl}
            target="_blank"
            rel="noreferrer"
            className="cntgtm_main_depositobpr_google_play"
          >
            <StaticImage
              src="../images/Google.png"
              alt="Google-logo"
              objectFit="contain"
              className="w-32"
              placeholder="none"
            />
          </a>
        </div>
        <div className={GTM_TRACKERS.HOME.APP_STORE}>
          <a
            href={smartScriptUrl}
            target="_blank"
            rel="noreferrer"
            className="cntgtm_main_depositobpr_app_store"
          >
            <StaticImage
              src="../images/Apple.png"
              alt="apple-logo"
              objectFit="contain"
              className="w-32"
              placeholder="none"
            />
          </a>
        </div>
      </div>
    </div>
    <div className="hidden lg:block lg:absolute lg:bottom-0 lg:right-2 w-[480px] z-0">
      <StaticImage
        src="https://cdn.sanity.io/images/iaemwymj/preview/bb1ff8502bc39f2003e64801948502f67d38fb23-1459x1636.webp"
        alt="Persuasion"
        objectFit="contain"
      />
    </div>
  </div>
);

CardDepositoBPR.propTypes = {
  smartScriptUrl: PropTypes.string,
};

CardDepositoBPR.defaultProps = {
  smartScriptUrl: DEFAULT_SMART_SCRIPT_URL,
};

export default CardDepositoBPR;
