import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import './style.css';

const dataCollection = [
  {
    question: 'Siapa Saja yang Dapat Menjadi Deposan DepositoBPR by Komunal?',
    /* eslint-disable-next-line max-len */
    answer: 'Seluruh Warga Negara Indonesia (WNI) dengan usia minimal 17 tahun dan memiliki Kartu Tanda Penduduk (KTP) elektronik. Seluruh Badan Usaha yang didirikan di Indonesia.',
  },
  {
    question: 'Apakah Dana Deposan Aman?',
    /* eslint-disable-next-line max-len */
    answer: 'DepositoBPR hanya bekerjasama dengan BPR yang merupakan peserta penjaminan LPS. Selain itu BPR adalah salah satu lembaga keuangan yang terdaftar dan diawasi oleh Otoritas Jasa Keuangan (OJK).',
  },
  {
    question: 'Apakah Informasi Pribadi Deposan Aman?',
    /* eslint-disable-next-line max-len */
    answer: 'Perusahaan berkomitmen untuk menjaga keamanan informasi pribadi para pengguna dengan berbagai upaya seperti enkripsi data, pembatasan akses staf internal, perlindungan firewall & antivirus, memilih server terkemuka dengan inspeksi dan back-up regular, dan upaya sekuritisasi keamanan lainnya.',
  },
];

export default function Accordion() {
  const [accordion, setActiveAccordion] = useState(-1);

  function toggleAccordion(index) {
    if (index === accordion) {
      setActiveAccordion(-1);
      return;
    }
    setActiveAccordion(index);
  }

  return (
    <div className="container mx-auto mb-10">
      <div className="accordion-faq">
        {dataCollection.map((item, index) => (
          <div
            key={item.question}
            onClick={() => toggleAccordion(index)}
            onKeyDown={() => toggleAccordion(index)}
            role="button"
            tabIndex="0"
          >
            <div className="flex justify-between">
              <div className="accordion-faq-heading">
                <h3 className={`${accordion === index ? 'active' : ''} text-sm font-medium accordion-question`}>
                  {item.question}
                </h3>
              </div>
              <div>
                {accordion === index ? (
                  <>
                    <span className="verticle active">
                      <FontAwesomeIcon icon={faAngleDown} />
                    </span>
                  </>
                ) : (
                  <>
                    <span className="verticle">
                      <FontAwesomeIcon icon={faAngleRight} />
                    </span>
                  </>
                )}
              </div>
            </div>
            <div>
              <p className={`${accordion === index ? 'active' : 'inactive'} text-sm lg:text-base text-gray-dark90`}>
                {item.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
