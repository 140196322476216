import * as React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

export default function Button(props) {
  return !props.isInternal ? (
    <a
      // eslint-disable-next-line max-len
      className={`text-center bg-gradient-to-b from-yellow-400 to-yellow-500 shadow-[0_6px_10px_0_rgba(0,0,0,0.25)] rounded-xl border-[1px] border-secondary-100 cursor-pointer ${props.className}`}
      href={props.href || '#'}
      onClick={props.onClick}
    >
      <p className="text-sm text-blue-light40 font-semibold">{props.text}</p>
    </a>
  ) : (
    <Link
      // eslint-disable-next-line max-len
      className={`text-center bg-gradient-to-b from-yellow-400 to-yellow-500 shadow-[0_6px_10px_0_rgba(0,0,0,0.25)] rounded-xl border-[1px] border-secondary-100 cursor-pointer ${props.className}`}
      to={props.href}
      onClick={props.onClick}
    >
      <p className="text-sm text-blue-light40 font-semibold">{props.text}</p>
    </Link>
  );
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  isInternal: PropTypes.bool,
};

Button.defaultProps = {
  onClick: undefined,
  className: '',
  isInternal: false,
};
